import React from "react";
import Template from "../components/Template/Template";
import SafeLink from "../components/SafeLink/SafeLink";
import BlogList from "../components/BlogList/BlogList";
import Section from "../components/Section/Section";
import Hero from "../components/Hero/Hero";
import hero from "./assets/svg/blog-hero.svg";

export default ({
  location,
  pageContext: { data, previousLink, nextLink, pageNumber, pagesTotal },
}) => {
  const seo = { title: "BSM - News and Blog Posts" };

  const posts = data.map(({ data, uri }) => {
    let imageUrl, title, body;

    for (let i = 0, section; i < data.sections.length; i++) {
      section = data.sections[i];
      if (section.component.component === "BlogMeta") {
        title = section.props.heading;
      }
      if (section.component.component === "BlogContent") {
        body = section.component.props.excerpt;
      }
      if (section.component.component === "FullWidthImage") {
        imageUrl = section.component.props.src;
      }
    }

    return {
      imageUrl: imageUrl,
      title: title,
      body: body,
      uri: uri,
    };
  });

  return (
    <Template seo={seo} location={location}>
      <Section className="bg-bsm-grey-6 top-fill">
        <Hero
          svg={hero}
          smallText="Read our latest"
          largeText="Blog posts"
          page="blog-archive"
        />
      </Section>
      <Section className="bg-bsm-primary-white blog-archive pt-5">
        {posts.length ? (
          <BlogList posts={posts} limit={0} />
        ) : (
          <div className="pt-5 mt5">No blog posts found!</div>
        )}
      </Section>
      {!!posts.length && (
        <Section>
          {previousLink ? <SafeLink to={previousLink}>prev</SafeLink> : null}
          <span className="page-counter">
            page {pageNumber} of {pagesTotal}
          </span>
          {nextLink ? <SafeLink to={nextLink}>next</SafeLink> : null}
        </Section>
      )}
    </Template>
  );
};
